<script>
  import { postData } from "$lib/services/store";
  import { slide } from "svelte/transition";
  import Button from "$lib/main/components/Button.svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Icons from "$lib/main/components/Icons.svelte";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";

  export let adminView = false;
  export let type;

  let open = false;
  let toc = []
  $: {
    toc = []
    for (let block of $postData?.content || []) {
      if (block.props?.tocData) {
        toc.push(block.props.tocData)
      }
    }
    toc = toc
  }
</script>

{#if adminView}
  <Group addClass="form-item">
    <Button>Refresh (doesn't work)</Button>
  </Group>
{:else if type == "scrollable"}
  {#if toc && toc.length > 0}
    <div class="toc-container">
      <nav>
        <ul>
          <li>
            <span><Translate text="Table of contents" />:</span>
          </li>
          {#each toc as tocItem}
            {#if tocItem.title}
              <li><a href={ "#" + tocItem.tag }>{ tocItem.title }</a></li>
            {/if}
          {/each}
        </ul>
      </nav>
    </div>
  {/if}
{:else}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="table-of-contents" on:click={() => open = !open}>
    <div class="row">
      <div class="header">
        <span><Translate text="Table of contents" /></span>
        <Icons name="chevron-right" strokeWidth="3" />
      </div>
    </div>
  </div>
  {#if toc && open}
    <ul in:slide={{ duration: 100 }} out:slide={{ duration: 100 }}>
      {#each toc as tocItem}
        <li><a href={ "#" + tocItem.tag }>{ tocItem.title }</a></li>
      {/each}
    </ul>
  {/if}
{/if}

<style>
  .table-of-contents {
    background-color: var(--bg-clr-ltr);
    border-bottom: 1px solid var(--bd-clr);
    cursor: pointer;
    color: var(--txt-clr);
    font-weight: 600;
    font-size: 0.875rem;
    padding: 0.5rem 0;
    margin: 0 0 1rem 0;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ul {
    display: inline-block;
    list-style: none;
    width: 100%;
    padding: 0;
    margin: -1rem 0 1rem 0;
    border-bottom: 1px solid var(--bd-clr);
  }
  ul li {
    color: var(--txt-clr);
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    border-bottom: 1px solid var(--bg-clr-ltr);
    transition: all 0.2s ease-in-out;
  }
  ul li:hover {
    cursor: pointer;
    background: var(--bg-clr-ltr);
  }
  ul li:last-of-type {
    border: none;
  }

  .toc-container {
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .toc-container::-webkit-scrollbar {
    display: none;
  }
  .toc-container nav {
    display: block;
  }
  .toc-container nav ul {
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }
  .toc-container nav ul li {
    display: inline-block;
    padding: 0;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0 1rem 0 0;
    cursor: inherit;
  }
  .toc-container nav ul li span {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.875rem;
    color: var(--clr-pri-txt);
  }
  .toc-container nav ul li a {
    color: var(--clr-pri-txt);
    text-decoration: none;
  }
  .toc-container nav ul li a:hover {
    color: var(--clr-pri-txt-hvr);
  }
</style>
