<script>
  import { slide } from 'svelte/transition';
  import { project } from "$lib/services/store";
  import { beforeNavigate } from '$app/navigation';
  import Group from "$lib/admin/components/Group.svelte";
  import Input from "$lib/admin/components/Input.svelte";
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';
  import Translate from '$lib/admin/components/blocks/helpers/Translate.svelte';

  export let props = null;
  export let adminView = false;

  let show = false;

  beforeNavigate(() => {
    if (show) {
      show = false;
    }
  })
</script>

{#if adminView}
  <Group addClass="form-item">
    <Group colspan="4-8">
      <Input type="select" bind:value={props.adDisclaimerType} label="Type" on:keyup>
        <option value="small">Small</option>
        <option value="full">Full</option>
      </Input>
    </Group>
  </Group>
{:else}
  {#if props?.adDisclaimerType == "full"}
    <div class="article-notes">
      <div class="row">
        <div class="commercial-note">
          <span>Reklame for Co-Gaming Limited.</span>
          <span>Annonce - Denne side indeholder <a href="https://www.betxpert.com/saadan-anmelder-betxpert-bookmakerne">reklamelinks*</a>.</span>
        </div>
        <div class="responsible-gaming">
          <span>SPILLEMYNDIGHEDENS HJÆLPELINJE: <a href="https://www.stopspillet.dk/" target="_blank" rel="noreferrer">STOPSPILLET.DK</a></span>
          <span>SELVUDELUKKELSE: <a href="https://www.rofus.nu/" target="_blank" rel="noreferrer">ROFUS.NU</a></span>
          <span>SPIL ANSVARLIGT | 18+</span>
        </div>
      </div>
    </div>
  {:else}
    <Block
      blockClass="block-disclaimer"
      gridColumn="g-col-12"
      {props}>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <span on:click={() => show = !show}><Translate text="Affiliate disclosure" /></span>
      {#if show}
        <div class="terms" in:slide={{ duration: 200 }} out:slide={{ duration: 200}}>
          <p>{@html $project.data?.affiliateDisclosure?.text ?? 'No information yet..'}</p>
        </div>
      {/if}
    </Block>
  {/if}
{/if}

<style>
  .article-notes {
    background: #eee;
    font-style: italic;
    font-size: .75rem;
    padding: 0.5rem 0;
    margin: 1rem 0;
    color: #666;
  }
  .article-notes .commercial-note {
    margin: 0 0 0.25rem 0;
    display: flex;
  }
  .article-notes .commercial-note span:last-of-type {
    text-align: right;
  }
  .article-notes .responsible-gaming {
    display: flex;
    flex-direction: column;
  }
</style>