<script>
  import { onMount } from "svelte";
  import { afterUpdate } from 'svelte';

  export let text = null;
  let editor = null;
  const id = Math.random().toString(16);

  // Update the editor when we swap blocks as this doesn't happen
  // when two blocks of the same type are swapped.
  afterUpdate(() => {
    if (editor && editor.getContents() !== text) {
      editor.setContents(text);
    }
  })

  onMount(async () => {
    // These MUST be dynamically imported, otherwise the build will fail.
    const suneditor = (await import("suneditor")).default;
    const plugins = (await import("suneditor/src/plugins")).default;

    editor = suneditor.create(id, {
      plugins: plugins,
      buttonList: [
        ["bold", "italic", "underline", "strike"],
        ["link", "blockquote"],
        ["list", "formatBlock"],
        ["removeFormat", "showBlocks"],
        ["codeView"]
      ],
      linkNoPrefix: true
    });
    editor.setContents(text);
    editor.onKeyUp = function (event, core) {
      text = event.target.innerHTML;
    };
  });
</script>

<div class="editor-wrapper" on:keyup>
  <div {id} />
</div>

<style>
  @import "suneditor/dist/css/suneditor.min.css";
</style>